em {
    font-style: normal !important;
    background-color: lightcyan;
    font-weight: bolder;
    color: blue;
}
button {
  border: none; /* 移除所有button的边框 */
  box-shadow: none; /* 如果有阴影也可以移除 */
  padding: 8px 16px; /* 添加或调整padding */
  background-color: transparent; /* 你可以设置背景颜色 */
  color: inherit; /* 保持文本颜色与父元素一致 */
  cursor: pointer; /* 确保鼠标指针在按钮上显示为手指形状 */
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* 确保没有水平滚动条 */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;  /* 确保 root 至少和视口一样高 */
}

/* 禁用滚动条的类 */
.no-scroll {
  overflow: hidden; /* 禁用垂直滚动条 */
}

/* App内容部分，占据剩余空间，不启用滚动条 */
.App {
  flex-grow: 1;
  overflow-y: auto; /* 允许内容滚动 */
  /* padding-bottom: 60px; */
}

/* Footer固定在底部 */
.footer {
  overflow-y: hidden;
}

.responsive-logo {
    width: 60%;
    max-width: 485px;
    height: auto;
  }

/* 當螢幕為橫屏或寬度大於高度，且寬度不同時調整 */
@media (orientation: landscape) and (min-width: 1200px) {
  .responsive-logo {
      width: 50%;
      max-width: 400px;
  }
}

@media (orientation: landscape) and (min-width: 992px) and (max-width: 1199px) {
  .responsive-logo {
      width: 40%;
      max-width: 300px;
  }
}

@media (orientation: landscape) and (min-width: 768px) and (max-width: 991px) {
  .responsive-logo {
      width: 30%;
      max-width: 250px;
  }
}

@media (orientation: landscape) and (max-width: 767px) {
  .responsive-logo {
      width: 20%;
      max-width: 200px;
  }
}

.responsive-logo-circle {
    width: 50%;
    height: auto;
    max-height: 200px;
  }

  .sui-multi-checkbox-facet {
    color: #4f4f4f;
    font-size: 15px !important;
    margin: 8px 0;
  }

  .euiCheckbox .euiCheckbox__input ~ .euiCheckbox__label {
    display: inline-block;
    padding-left: 24px;
    line-height: 24px;
    font-size: 14px;
    position: relative;
    z-index: 0 !important;
    cursor: pointer;
}


.sui-paging-info {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: #4a4b4b;
  font-size: 12px;
  display: inline-block;
  margin-left: 25px;
}



/* =========================== */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80vw;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}


/* The Close Button */
.closemodal {
  color: rgb(0, 0, 0);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closemodal:hover,
.closemodal:focus {
  color: rgb(243, 0, 0);
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #ffffff;
  color: rgb(10, 10, 10);
}

.modal-body {padding: 2px 16px;}

.modal-footer {
  padding: 16px 16px;
  background-color: #e6e6e6de;
  color: rgb(0, 0, 0);
}

/* =========================== */

img:hover {
  cursor: pointer;
}

.footer {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  display: flex;
  flex-wrap: wrap; /* 当宽度不足时自动换行 */
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直居中对齐 */
  gap: 10px; /* 项目之间的间距 */
  margin: 15px;
  z-index: 1000; /* 确保 footer 位于其他元素之上 */
  overflow: visible;       /* 确保内容可见 */
  height: auto;            /* 自适应内容高度 */
}

/* ========================== Searchbox */
.searchbutton{
  border-color: #c8d1e0 !important;
}

.euiPage--paddingLarge {
  padding: 0px;
}

.bar{
  margin:0 auto;
  width:175px;
  border-radius:30px;
  border:1px solid #dcdcdc;
  background-color: white;
}
.bar-result{
  margin:0 auto;
  width:175px;
  border-radius:30px;
  border:1px solid #dcdcdc;
  background-color: white;
}
.bar:hover{
  box-shadow: 1px 1px 8px 1px #dcdcdc;
}
.bar:focus-within{
  box-shadow: 1px 1px 8px 1px #dcdcdc;
  outline:none;
}
.searchbar{
  height:45px;
  border:none;
  width:500px;
  font-size:16px;
  outline: none;
}
.voice{
  height:23px;
  position:relative;
  top:12px;
  left:10px;
  width: auto !important;
}
.voice-result{
  height:20px;
  position:relative;
  top:16px;
  left:40px;
  width: auto !important;
  margin-right: 35px;
}

.searchbutton-result{
  height: auto !important;
  width: 30px !important;
  margin-left: 20px;
  margin-right: 15px
}

.searchbar-result{
  font-size: 16px !important;
  border:none !important;
  width:100% !important; 
  margin-left: 20px;
  box-shadow:none !important;
}
.searchbar-result:focus-within{
border-bottom: none !important;
border-top: none !important;
border-left: none !important;
border-right:none !important;
box-shadow: none !important;
}
.yt-container{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  vertical-align: top; /*bottom;*/
}
.yt-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 98%;
}

@media (max-width: 650px) {
 .bar {
    margin: 0 auto;
    width: 100%;
    border-radius: 30px;
    border: 1px solid #dcdcdc;
  }
  .bar-result {
    margin: 0 auto;
    width: 100%;
    border-radius: 30px;
    border: 1px solid #dcdcdc;
  }  
  .searchbar{
    height: 45px;
    border: none;
    width: 80%;
    font-size: 16px;
    outline: none;  
  }
  .voice {
    height: 23px;
    position: relative;
    top: 12px;
    left: 5px;
}
  .voice-result {
    height: 22px;
    position: relative;
    top: 13px;
    left: 39px;
  }
}
@media (min-width: 650px) {
  .bar{
    margin:0 auto;
    width:575px;
    border-radius:30px;
    border:1px solid #dcdcdc;
  }
  .bar-result{
    margin:0 auto;
    width:100%;
    border-radius:30px;
    border:1px solid #dcdcdc;
    box-shadow:none !important;
  }  
  .searchbar{
    height:45px;
    border:none;
    width:500px;
    font-size:16px;
    outline: none;
    
  }
}

form[role="combobox"] {
  width: 100%;  /* 确保 form 占满整个宽度 */
  display: flex;
}

/* ========================== Resultset Menu Link  */

.menulink{
  display: block;
}

@media (max-width: 767px) {
.menulink{
  display: none !important;
}
}

.popmenulink{
  display: none !important;
  justify-content: left!important;
}

@media (max-width: 767px) {
.popmenulink{
  display: block !important;
  justify-content: left!important;
}
}

.login-form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}

.reset-password-container .euiForm {
  max-width: 400px;
  width: 100%;
}

/* 普通歌单项的样式 */
.playlist-item {
  color: #000; /* 默认文字颜色 */
}

.playlist-item.selected-playlist .euiListGroupItem__label{
  font-weight: bold !important;
  color: #0070f3 !important;
  /* background-color: #f0f0f0 !important; */
}

.song-item.playing-song .euiListGroupItem__label{
  font-weight: bold !important;
  color: #d9534f !important;
}

.panel-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 665px) { /* 这里600px是你设定的阈值，可以调整 */
  .panel-toggle-button {
      align-items: flex-start !important;
      justify-content: flex-start !important; /* 使按钮靠左 */
    }
  }

  .euiTitle {
    font-size: 24px !important; /* 将字体大小设置为 24px，您可以根据需要调整 */
  }

  .App {
    width: 100%;
}

@media screen and (max-width: 668px) {
  .radio-item {
    grid-gap: 0px !important;
    padding: 0px !important;
  }
  .radio-grid {
    grid-template-columns: repeat(3, 1fr) !important;
    grid-gap: 0px !important;
    padding: 0px !important;
  }
}

/* 竖屏模式 */
@media screen and (orientation: portrait) {
  .panel-toggle-button {
      align-items: flex-start !important;
      justify-content: flex-start !important; /* 使按钮靠左 */
  }
  .responsive-flex-group {
    flex-direction: column !important; /* 竖屏时垂直排列 */
    height: 100% !important;
  }

  .left_video_item{
    height: 40% !important;
    width: 100%;
  }

  .rigth_song_info {
    width: 100%; /* 在竖屏时，占据整行 */
  }
  .radio-player {
    width: calc(100vw - 20px) !important;
    height: calc((100vw * 9 / 16) - 11.25px) !important;
  }
  .radio-player-full {
    width: calc(100vw - 20px) !important;
    height: calc((100vw * 9 / 16) - 11.25px) !important;
  }
  .playlist-player {
    width: calc(100vw - 20px) !important;
    height: calc((100vw * 9 / 16) - 11.25px) !important;
  }
  .playlist-player-full {
    width: calc(100vw - 20px) !important;
    height: calc((100vw * 9 / 16) - 11.25px) !important;
  }
}

/* 横屏模式，适用于所有屏幕 */
@media screen and (orientation: landscape) {
  .responsive-flex-group > .euiFlexItem {
    flex-basis: auto !important; /* 重置 flex-basis */
  }

  /* 默认样式：横屏模式下 */
  .responsive-flex-group {
    display: flex !important;
    flex-direction: row !important;
    height: 100% !important;
    align-items: flex-start !important; /* 子元素靠上对齐 */
  }

  .left_video_item {
    position: sticky;
    top: 0;
    z-index: 100;
    flex-grow: 2 !important;
    width: 60vw !important; /* 根据需要调整视频区域的宽度 */
  }
  .left_video_item_full{
    width: 90vw !important; /* 根据需要调整视频区域的宽度 */
  }

  .rigth_song_info {
    width: 30vw !important; /* 根据需要调整列表区域的宽度 */
    flex-grow: 1 !important;
  }

  .radio-player {
    /* width: 60vw !important;
    height: calc(60vw* 9 / 16) !important; */
    width: calc(var(--viewport-height) * 16 / 9) !important;
    height: var(--viewport-height) !important;
  }

  .radio-player-full {
    width: calc(var(--viewport-height) * 16 / 9) !important;
    height: var(--viewport-height) !important;
  }
  .playlist-player {
    width: 60vw !important;
    height: calc(60vw* 9 / 16) !important;
  }

  .playlist-player-full {
    width: calc(var(--viewport-height) * 16 / 9) !important;
    height: var(--viewport-height) !important;
  }
}


/* 屏幕寬度小於780px，並且在横屏模式下 */
@media only screen and (max-width: 745px) and (orientation: landscape) {
    .responsive-flex-group {
      flex-direction: row !important; /* 强制左右布局 */
      flex-wrap: nowrap !important;
      align-items: flex-start !important; /* 垂直方向上对齐到顶部 */
      justify-content: space-between !important; /* 在水平方向上分布元素 */
    }

    .left_video_item {
      max-width: 100% !important; /* 左边元素宽度50% */
      width: 60vw !important; /* 根据需要调整视频区域的宽度 */
    }

    .center_toggle_button{
      width: 5vw !important; /* 根据需要调整视频区域的宽度 */
    }

    .rigth_song_info {
      flex-basis: 50% !important;
      max-width: 100% !important; /* 右边元素宽度50% */
      width: 35vw !important; /* 根据需要调整视频区域的宽度 */
      overflow-y: auto !important; /* 如果右侧内容超出高度，可以滚动 */
    }
}

.song-list-container {
  width: 300px;
  height: 100vh; /* 适配窗口高度 */
  overflow-y: auto; /* 超出时自动显示滚动条 */
}

.song-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.song-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.song-list li:hover {
  background-color: #f5f5f5;
}

.custom-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border-radius: 15px; /* 圓角框 */
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 8100;
  width: 400px;
  text-align: center;
}

.custom-alert p {
  font-size: 20px;
  margin-bottom: 20px;
}

.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8099;
}

.button-group {
  display: flex;
  justify-content: space-around;
}

.btn-confirm {
  background-color: #4CAF50; /* 綠色按鈕 */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px; /* 圓角按鈕 */
  cursor: pointer;
}

.btn-cancel {
  background-color: #ccc; /* 灰色按鈕 */
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 10px; /* 圓角按鈕 */
  cursor: pointer;
}

.btn-confirm:hover, .btn-cancel:hover {
  opacity: 0.8;
}

/* 默认隐藏删除图标 */
.delete-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* 当鼠标悬停在列表项时，显示删除图标 */
.song-item:hover .delete-icon {
  opacity: 1;
}

.euiToolTip {
  z-index: 99999; /* 提高 z-index 确保它在其他元素之上 */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 每行 5 列 */
  grid-gap: 10px; /* 按钮之间的间距 */
  margin-bottom: 20px;
  justify-items: center; /* 按钮在网格中水平居中 */
}

.book-button {
  background-color: white;
  border: 1px solid #ddd;
  width: 100px;  /* 固定宽度 */
  height: 60px; /* 固定高度 */
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s;
}

.book-button:hover {
  transform: scale(1.05); /* 鼠标悬停时按钮放大 */
}

.book-name {
  font-size: 18px; /* 书名字体大小 */
  font-weight: bold;
}

.book-subtitle {
  font-size: 14px; /* 副标题字体大小 */
  color: #555;
}

/* 响应式设计，当屏幕较小时，调整列数 */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr); /* 每行 3 列 */
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr); /* 每行 2 列 */
  }
}

.audio-container {
  width: 410px; /* 默认占满容器 */
  max-width: 500px; /* 最大宽度 */
  margin: 0 auto; /* 居中 */
}

@media (max-width: 480px) {
  .audio-container {
    width: 100% !important;
  }
}
