/* Main chat container as popup */
.chat-ui {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  max-width: 90%;
  height: 500px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

/* Header of the popup chat */
.chat-header {
  padding: 10px;
  background-color: #4a90e2;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Chat message window */
.chat-window {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  background-color: #f4f4f9;
}

/* User message bubble - align right */
.user-msg {
  text-align: right;
  background-color: #daf1da;
  color: #333;
  margin: 5px 0 5px auto; /* Aligns the bubble box to the right */
  padding: 10px;
  border-radius: 10px 10px 0 10px;
  max-width: 75%;
  align-self: flex-end; /* Ensures the entire bubble aligns to the right */
}

/* AI (assistant) message bubble - align left */
.assistant-msg {
  text-align: left;
  background-color: #e2e8f0;
  color: #333;
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px 10px 10px 0;
  max-width: 75%;
  align-self: flex-start;
}

/* Typing indicator */
.typing-indicator {
  font-style: italic;
  color: #888;
  margin: 5px;
  padding: 10px;
  align-self: flex-start;
}

/* Quick prompt link */
.quick-prompt {
  text-align: left;
  cursor: pointer;
  color: #4a90e2;
  margin-bottom: 10px;
  margin-left: 10px;
}

/* Chat input area at the bottom */
.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #ffffff;
}

.chat-input input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.chat-input button {
  padding: 8px 12px;
  background-color: #4a90e2;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #357abd;
}

.input-wrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center; /* 垂直居中输入框和图标 */
  padding-right: 10px; /* 增加输入框和发送按钮之间的距离 */
}

.input-field {
  width: 100%;
  padding-right: 40px; /* 为图标预留空间 */
  box-sizing: border-box;
  height: 40px;
}

.voice-icon {
  margin-left: -30px; /* 使图标靠近输入框内部 */
  margin-right: 10px; /* 增加图标和发送按钮之间的间距 */
  cursor: pointer;
  color: inherit; /* 与输入框一致的颜色 */
}
